<script>
import appConfig from "@/app.config";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
 
  data() {
    return {
      data: {},
      marketingCampaignMembers: [],
      archive_files_types: [],
      marketingTeamMembers: [],
      marketingAllTeamMembers: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchmarketingCampaignMembers: null,
      marketingCampaignMembersSearchMode: false,
      searchModel: "",
      marketingCampaignAreas: [],
    };
  },
  methods: {
    getMarketingCampaignAreasByCampaignId() {
      this.http
        .post("marketing-campaign-areas/by-campaign-id", {
          marketing_campaign_id: this.$route.params.id,
        })
        .then((res) => {
          if (res.status) {
            this.marketingCampaignAreas = res.data;
            this.marketingCampaignAreas = this.marketingCampaignAreas.map(
              (area) => ({
                check_id: area.marketing_area_id,
                label: area.marketing_area?.name,
              })
            );

            console.log("step 34 :", this.marketingCampaignAreas);
          }
        });
    },
    addmarketingCampaignMembers() {
      var data = {
        title: "popups.add",
        inputs: [
          {
            model: "marketing_team_member_id",
            type: "select",
            options: (() => {
              return this.marketingTeamMembers.map((data) => {
                return { value: data.id, label: data.name };
              });
            })(),
            label: "popups.add_marketing_team_member",
          },
          {
            model: "marketing_campaign_team_member_areas",
            type: "checkbox",
            label: "marketing.tabs.areas",
            options: this.marketingCampaignAreas,
          },
          {
            model: "target",
            type: "number",
            label: "marketing.team_member.target",
          },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.marketing_campaign_id = this.$route.params?.id;
              console.log("**********####", obj);
              this.http.post("marketing-campaign-team-members", obj).then(() => {
                this.get(this.page);
                this.getMarketingTeamMembers();

              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    editMarketingTeamMembers(app) {

      console.log('step 103 :', app?.marketing_campaign_team_member_areas);
      if (!app?.marketing_campaign_team_member_areas)
        app.marketing_campaign_team_member_areas = []
      this.marketingCampaignAreas =this.marketingCampaignAreas.map(area => ({ check_id: area.check_id, label: area.label, checked: app?.marketing_campaign_team_member_areas.find(i => i?.marketing_area_id == area?.check_id) ? true : false }))
      
      var data = {
        title: "popups.edit",
        inputs: [
          {
            model: "marketing_campaign_team_member_areas",
            type: "checkbox",
            label: "marketing.tabs.areas",
            options: this.marketingCampaignAreas,
          },
          {
            model: "target",
            type: "number",
            label: "target",
            value: app.target,
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http
                .put("marketing-campaign-team-members", app.id, obj)
                .then(() => {
                  this.get(this.page);
                  this.getMarketingTeamMembers();
                });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      this.marketingCampaignMembersSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("marketing-campaign-team-members/by-campaign-id/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "name",
          marketing_campaign_id: this.$route.params?.id,
        })
        .then((res) => {
          this.marketingCampaignMembers = res.data;
          console.log("######## files", this.marketingCampaignMembers);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.marketingCampaignMembersSearchMode = false;
      this.get(this.page);
    },
    getAllMarketingTeamMembers() {
      this.http
        .get(
          "marketing-campaign-team-members/by-member-id/" +
          this.$route.params?.id
        )
        .then((res) => {
          this.marketingAllTeamMembers = res.data;
        });
    },
    getMarketingTeamMembers() {
      this.http
        .get(
          "marketing-campaign-team-members/by-team-member/" +
          this.$route.params?.id
        )
        .then((res) => {
          this.marketingTeamMembers = res.data;
        });
    },
 
    deletemarketingCampaignMembers(app) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http
            .delete("marketing-campaign-team-members", app.id)
            .then(() => {
              console.log("############################3");
              this.get(this.page);
              this.getMarketingTeamMembers();
            });
        }
      });
    },
    get(page) {
      console.log(page);
      this.http
        .post("marketing-campaign-team-members/by-campaign-id/pagination", {
          limit: this.limit,
          page: page,
          marketing_campaign_id: this.$route.params?.id,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          console.log({ returnedData: res.data });
          this.marketingCampaignMembers = res.data;
        });
    },
  },
  created() {
    this.get(1);
    this.getMarketingTeamMembers();
    this.getAllMarketingTeamMembers();
    this.getMarketingCampaignAreasByCampaignId();
  },
  mounted() {
  },
};
</script>

<template>
  <div class="row align-items-center">
    <div class="col-4"></div>

    <div class="col-8">
      <div class="search-box chat-search-box d-flex align-items-center" style="width: 250px; margin-inline-start: auto">
        <div class="position-relative mx-3 d-flex align-items-center">
          <input @keyup="search()" type="text" class="form-control fa-lg text-light"
            :dir="$i18n.locale == 'ar' ? 'rtl' : ''" :placeholder="$t('users.searchplaceholder')" v-model="searchModel"
            style="background-color: #2a3042 !important" />
          <i class="bx bx-search-alt search-icon text-light"></i>
          <button @click="cancelappsearchMode()" v-if="searchModel" class="btn-close text-light"
            style="position: absolute; top: 12px; left: 250px"></button>
        </div>
        <button type="button" class="btn btn-light float-end" @click="addmarketingCampaignMembers">
          <span class="bx bxs-plus-circle float-end fa-2x" style="color: #2a3042 !important"></span>
        </button>
      </div>
    </div>
  </div>
  <!-- end row -->

  <hr class="mb-4" />

  <div class="row">
    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
      <thead>
        <tr class="text-light text-center" style="background-color: #2a3042 !important">
          <th scope="col">
            {{ $t("archiving_documents.archiving_documents_table.id") }}
          </th>
          <th scope="col">
            {{ $t("archiving_categories.archiving_categories_table.name") }}
          </th>
          <th scope="col">
            {{ $t("marketing.team_member.accomplish") }}
          </th>
          <th scope="col">
            {{ $t("marketing.team_member.target") }}
          </th>
          <th scope="col">
            {{ $t("archiving_documents.archiving_documents_table.created") }}
          </th>
          <th scope="col">
            {{ $t("archiving_documents.archiving_documents_table.updated") }}
          </th>
          <th scope="col">
            {{ $t("archiving_documents.archiving_documents_table.operations") }}
          </th>
          "
        </tr>
      </thead>
      <tbody>
        <tr v-for="(app, i) in marketingCampaignMembers" :key="app" class="text-center">
          <td>{{ i + 1 }}</td>
          <td>{{ app?.name }}</td>
          <td>{{ app?.accomplished }}</td>
          <td>{{ app?.target }}</td>
          <td>{{ app?.created.split("T")[0] }}</td>
          <td>{{ app?.updated.split("T")[0] }}</td>
          <td class="d-flex justify-content-end">
            <a class="btn btn-primary mx-1" @click="editMarketingTeamMembers(app)" href="javascript: void(0);"
              role="button">{{ $t("popups.edit") }}
            </a>
            <a class="btn btn-danger" href="javascript: void(0);" role="button"
              @click="deletemarketingCampaignMembers(app)">{{ $t("popups.delete") }}</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- end  table -->

  <!-- Button trigger modal -->

  <div v-if="tot_pages == 20">
    <!--   Apps  Pagination     -->
    <ul class="pagination pagination-rounded justify-content-center mb-2">
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a class="page-link" href="javascript: void(0);" @click="get(page - 1)" aria-label="Previous">
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li class="page-item" :class="{ active: p == page }" v-for="p in tot_pages" :key="p">
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
          p
          }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == total_pages }">
        <a class="page-link" href="javascript: void(0);" @click="get(page + 1)" aria-label="Next">
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
  </div>


  

  

  <!--end-->
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>
